import React from 'react'

export default function Loader() {
  return (
    <div className='loaderdiv' style={{zIndex:-1}}>
            <div className='loader'></div>

    </div>
  )
}
